import React from "react";

const RefundPolicy = () => {

    const styles = {
        body: {
            fontFamily: 'Poppins, sans-serif',
            fontWeight: 600,
            backgroundColor: '#ffffff',
            margin: 0,
            padding: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            minHeight: '100vh',
            color: '#333',
        },

        privacyCard: {
            maxWidth: '75%',
            width: '100%',
            margin: '20px',
            backgroundColor: '#ffffff',
            borderRadius: '10px',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
            padding: '40px',
            boxSizing: 'border-box',
            overflowY: 'auto',
        },

        sectionTitle: {
            fontSize: '18px',
            color: '#333',
            marginBottom: '15px',
            fontWeight: 550,
        },


        sectionContent: {
            fontSize: '15px',
            lineHeight: 1.6,
            marginBottom: '20px',
            fontWeight: 400,
            letterSpacing: '.2px',
        },

        sectionContentLink: {
            color: '#007BFF',
            textDecoration: 'none',
        },

        sectionContentLinkHover: {
            textDecoration: 'underline',
        },

        section: {
            marginBottom: '30px',
        },
    };


    return (
        <div className="body" style={styles.body}>
            <div className="privacy-card" style={styles.privacyCard}>
                <h4 style={{ fontSize: '18px', fontWeight: '600' }}>Refund & Cancellation Policy</h4>
                <div className="section-content" style={styles.sectionContent}>
                    <p style={{ marginTop: '40px' }}>
                        Prozo Integrated Logistics Private Limited (formerly, Prozo Distribution Private Limited) Refund Policy
                        for Prozo Wallet
                    </p>
                    <h3 className="section-title" style={styles.sectionTitle}>1. Introduction</h3>
                    <p>
                        This refund policy outlines the terms and conditions for the refund of amounts paid through any payment
                        gateway to Prozo . The policy applies to the recharge of the Prozo (ProShip) wallet, which is used to
                        avail Prozo's logistics services.
                    </p>
                    <p>Prozo Integrated Logistics Private Limited (formerly, Prozo Distribution Private Limited) is referred to
                        as ‘Prozo’ in this Policy document.
                    </p>

                    <h3 className="section-title" style={styles.sectionTitle}>2. Eligibility for Refund</h3>
                    <p>Consequent to utilization of the wallet, in case a customer wants the unutilised and undisputed amount
                        refunded back, the customer will have to make a request for the refund of such balance with valid
                        documentary evidence with the Prozo logistics and Prozo finance teams. Once the amount is validated by
                        the finance team, the same shall be processed through NEFT. The refund will be initiated only after a
                        thorough reconciliation of the transactions.
                    </p>

                    <h3 className="section-title" style={styles.sectionTitle}>3. Process for Refund</h3>
                    <ul className="section-content" style={styles.sectionContent}>
                        <li>
                            <p>Placing of request : Customer shall place the request of refund with the Prozo Logistics team and
                                Prozo finance team with documentary evidence and bank details, copy of canceled cheque along
                                with
                                KYC documents.</p>
                        </li>
                        <li>
                            <p> Reconciliation and validation: All transactions will be reconciled and validated to ensure the
                                amount claimed for a refund is unutilized and undisputed.</p>
                        </li>
                        <li>
                            <p>Refund Initiation: Once the reconciliation is complete, the refund will be initiated and
                                processed
                                to the designated bank account shared by the user with Prozo. The refund process will be offline
                                and
                                any applicable refund shall be processed through the NEFT.</p>
                        </li>
                    </ul>

                    <h3 className="section-title" style={styles.sectionTitle}>4. Timeframe for Refund</h3>
                    <ul className="section-content" style={styles.sectionContent}>
                        <li>
                            <p>Refunds will be processed within 7 working days after the reconciliation and KYC verification (if
                                applicable) are completed.</p>
                        </li>
                        <li>
                            <p>The actual time for the amount to reflect in the customer's account may vary depending on the
                                banking
                                procedures.</p>
                        </li>
                    </ul>
                    <h3 className="section-title" style={styles.sectionTitle}>5. Dispute Resolution</h3>
                    <ul className="section-content" style={styles.sectionContent}>
                        <li>
                            <p>In case of any disputes regarding the transactions or the refund process, customers are advised
                                to
                                contact Prozo's logistics team.</p>
                        </li>
                        <li>
                            <p>Disputes will be resolved as per Prozo's dispute resolution policy and may require additional
                                documentation or evidence from the customer.</p>

                        </li>
                    </ul>

                    <h3 className="section-title" style={styles.sectionTitle}>6. Contact Information</h3>
                    <p>For any queries or assistance regarding the refund policy, customers can reach out to Prozo's customer
                        support at:</p>
                    <ul>
                        <li>Email: <a href="mailto:d2cfreight@prozo.com" style={styles.sectionContentLink}>d2cfreight@prozo.com</a>,
                            <a href="mailto:financeteam@prozo.com" style={styles.sectionContentLink}>financeteam@prozo.com</a>.</li>
                    </ul>

                    <h3 className="section-title" style={styles.sectionTitle}>7. Policy Updates</h3>
                    <p>Prozo reserves the right to update or modify this refund policy at any time. Changes to the policy will
                        be communicated through the Prozo website and other appropriate channels.
                    </p>

                    <h3 className="section-title" style={styles.sectionTitle}>8. Acceptance of Policy</h3>
                    <p>By using Prozo's services and recharging the Prozo (ProShip) wallet, customers agree to the terms and
                        conditions outlined in this refund policy.
                    </p>

                    <h3 className="section-title" style={styles.sectionTitle}>9. Governing Law</h3>
                    <p>This refund policy is governed by the laws of the jurisdiction where Prozo operates. Any legal disputes
                        arising from this policy will be subject to the exclusive jurisdiction of the Gurgaon, Haryana courts. .
                    </p>
                </div>
            </div>
        </div>
    );
};

export default RefundPolicy;
