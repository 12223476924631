import React from "react";

const PrivacyPolicy = () => {
    const styles = {
        body: {
            fontFamily: 'Poppins, sans-serif',
            fontWeight: 600,
            backgroundColor: '#ffffff',
            margin: 0,
            padding: 0,
            display: 'flex',
            justifyContent: 'center',
            alignItems: 'flex-start',
            minHeight: '100vh',
            color: '#333',
        },

        privacyCard: {
            maxWidth: '75%',
            width: '100%',
            margin: '20px',
            backgroundColor: '#ffffff',
            borderRadius: '10px',
            boxShadow: '0 4px 10px rgba(0, 0, 0, 0.1)',
            padding: '40px',
            boxSizing: 'border-box',
            overflowY: 'auto',
        },

        privacyCardHeading: {
            fontSize: '32px',
            color: '#333',
            textAlign: 'center',
            marginBottom: '30px',
            fontWeight: 700,
        },

        sectionTitle: {
            fontSize: '18px',
            color: '#333',
            marginBottom: '15px',
            fontWeight: 600,
        },

        sectionContent: {
            fontSize: '16px',
            color: '#8492a6',
            lineHeight: 1.6,
            marginBottom: '20px',
            fontWeight: 400,
            letterSpacing: '.2px',
        },

        sectionContentLink: {
            color: '#007BFF',
            textDecoration: 'none',
        },

        sectionContentLinkHover: {
            textDecoration: 'underline',
        },

        section: {
            marginBottom: '30px',
        },
    };

    return (
        <div style={styles.body}>
            <div class="privacy-card" style={styles.privacyCard}>
                {/* <h1 style={styles.privacyCardHeading}>Privacy Policy</h1> */}
                <div class="section" style={styles.section}>
                    <h2 class="section-title" style={styles.sectionTitle}>Introduction:</h2>
                    <p class="section-content" style={styles.sectionContent}>
                        At Prozo Integrated Logistics Private Limited (formerly, Prozo Distribution Private Limited) (“Prozo”)
                        we are committed to respecting the privacy rights of all visitors to our Websites: www.prozo.com
                        (referred to as “Websites” or “Sites”) and mobile app(s): The Books App, Bookz School App and Bookz
                        Admin App (referred to as “app(s)” or “mobile app(s) or"application(s)" or "mobile application(s)”).
                        Prozo regards customer privacy as an important part of our relationship with our customers. We at Prozo
                        have created this Privacy Policy to describe our practices regarding information we receive and collect
                        from you on our Websites, mobile applications, apps, and other interactive features or services on our
                        Sites that post a link to this Privacy Policy.
                    </p>
                    <p class="section-content" style={styles.sectionContent}>
                        Please note that this Privacy Policy (“Policy”) is only applicable to the users of the Websites and our
                        mobile application(s), and the information and data gathered on the Websites and/or our mobile
                        application(s) and not to any other information or websites. This Policy lets you know what information
                        we collect, how we may share that information, and your choices regarding the sharing of your
                        information. This Policy applies to the Websites and apps and other places where we may collect personal
                        and other information.
                    </p>
                    <p class="section-content" style={styles.sectionContent}>
                        You are hereby advised to read this Policy carefully and fully understand the nature and purpose of
                        gathering and/or collecting sensitive, personal and other information and the usage, disclosure and
                        sharing of such information. By viewing or accessing the Websites and app(s) or by requesting any
                        services through the Websites or our mobile application(s), you hereby also consent to Prozo sharing
                        information with third party service providers engaged by Prozo in relation to its operations and to the
                        transfer and/or storage of Personal Information (as defined herein) and data across borders. If you have
                        questions or concerns regarding this statement, please contact us at admin@prozo.com.
                    </p>
                </div>

                <div class="section" style={styles.section}>
                    <h2 class="section-title" style={styles.sectionTitle}>This Policy explains:</h2>
                    <ul class="section-content" style={styles.sectionContent}>
                        <li>The information we may collect about you online;</li>
                        <li>The use and role of cookies and similar technologies on our Websites and mobile application(s);</li>
                        <li>How we will protect and use information we collect about and from you;</li>
                        <li>The circumstances in which we might disclose your details to anyone else;</li>
                        <li>When we may use your details to contact you; and</li>
                        <li>How you can be sure the information we hold about you is accurate and current.</li>
                    </ul>
                </div>

                <div class="section" style={styles.section}>
                    <h2 class="section-title" style={styles.sectionTitle}>Collection of Information:</h2>
                    <p class="section-content" style={styles.sectionContent}>
                        In general, you can visit our Websites and mobile application(s) without revealing your identity or any
                        personal information about yourself. In order to use the Prozo Websites and mobile application(s), we
                        may require information from you in order to provide the best service possible.
                    </p>

                    <ul class="section-content" style={styles.sectionContent}>
                        <li>We may collect personal information such as contact information including your name, phone number,
                            billing, temporary or permanent residence/address, shop address and business registration details,
                            email address etc. We may also collect demographic information; purchase information, including
                            credit/debit card number, billing and/or shipping information, PayPal (or some other form of
                            electronic-payment) information.</li>
                        <li>We may further collect information regarding your interactions with our Websites and mobile
                            application(s) using cookies or click stream technology.</li>
                        <li>We may obtain additional personal and non-personal information from third party providers.</li>
                    </ul>
                    <p class="section-content" style={styles.sectionContent}>
                        All correspondence may also be collected and stored, particularly in regard to sales, support and
                        accounts,
                        including email. Any information collected by Prozo is collected via correspondence from you or your
                        company. This may be via the telephone, email, mail, fax or directly through our Websites and mobile
                        application(s).
                    </p>
                </div>

                <div class="section" style={styles.section}>
                    <h2 class="section-title" style={styles.sectionTitle}>Personal Information:</h2>
                    <p class="section-content" style={styles.sectionContent}>
                        “Personal Information” means any information that may be used to identify you and that is not otherwise
                        publicly available, including, but not limited to, your first and last name, an email address, phone
                        number, place of residence, place of business or other contact information. We may collect Personal
                        Information from you when you register for any of our services or otherwise voluntarily provide such
                        information.
                    </p>
                </div>

                <div class="section" style={styles.section}>
                    <h2 class="section-title" style={styles.sectionTitle}>Information Prozo collects automatically when you access the Websites and mobile
                        application(s):</h2>
                    <p class="section-content" style={styles.sectionContent}>
                        Like other websites and online services, we and our analytics providers and other third party service
                        providers may collect certain "Usage Information" whenever you access and use the Websites and mobile
                        application(s). Usage Information may include the browser and operating system you are using, the URL or
                        advertisement that referred you to the Websites and mobile application(s), the search terms you entered
                        into a search engine that lead you to the Websites and mobile application(s), all of the areas within
                        the Websites and mobile application(s) that you visit, and the time of day, among other information. We
                        may use Usage Information for a variety of purposes, including enhancing or otherwise improving the
                        Website and mobile application(s) and our products and services.
                    </p>
                    <p class="section-content" style={styles.sectionContent}>
                        In addition, we may automatically collect your IP address or other unique identifier ("Device
                        Identifier") for any computer, mobile phone or other device (any, a "Device") you may use to access the
                        Websites and mobile application(s). A Device Identifier is a number that is automatically assigned to
                        your Device used to access a service, and our servers may choose to identify your Device by its Device
                        Identifier. Some mobile service providers may also provide us or our third party service providers with
                        information regarding the physical location of the Device used to access a service. Usage Information is
                        generally non-identifying, but if we associate it with you as a specific and identifiable person, we
                        treat it as Personal Information.
                    </p>
                    <p class="section-content" style={styles.sectionContent}>
                        Usage Information is collected via various tracking technologies and may include:
                    </p>
                    <ul class="section-content" style={styles.sectionContent}>
                        <li>Cookies and Log Files: Like most websites and mobile application(s), the Prozo Websites and mobile
                            application(s) use cookies and web log files to track usage of our Websites and mobile
                            application(s). A cookie is a tiny data file which resides on your computer or mobile phone, and
                            which allows Prozo to recognize you as a user when you return using the same computer or phone and
                            web browser. If your browser settings do not allow cookies, you may not be able to use our Websites
                            and mobile application(s) as fully as intended. Log files are the record of your visits to the
                            Websites and mobile application(s) and include Usage Information. Like the information you enter at
                            registration or in your public profile, cookie and log file data is used to customize your
                            experience when you use the Websites and mobile application(s).
                        </li>
                    </ul>
                    <p class="section-content" style={styles.sectionContent}>
                        One type of cookie we may use is considered a "persistent" cookie and is set once you have logged in to
                        your account on the Websites and mobile application(s). If you do not log out of your account, then the
                        next time you visit, the persistent cookie will allow the Websites and mobile application(s) to
                        recognize you as an existing user so you will not need to log in before using the Websites and mobile
                        application(s). Of course, if you are using a public computer or sharing your private computer with
                        others, you should log out of your account after each visit. If you log out of your account on the
                        Websites and mobile application(s), you will need to re-enter your password the next time you visit in
                        order to log in to your account. We also may use a cookie which functions as a "session" cookie, meaning
                        that it is used to identify a particular visit. Session cookies expire after a short time or when you
                        close your web browser/mobile app.
                    </p>
                    <ul class="section-content" style={styles.sectionContent}>
                        <li>Embedded Scripts: Embedded script is a programming code that is designed to collect information
                            about your interactions with the Websites and mobile application(s) such as the links you click on.
                            The code is temporarily downloaded onto your computer or other devices from our server or a third
                            party service provider and is deactivated or deleted when you disconnect from the Websites or mobile
                            application(s).
                        </li>
                    </ul>
                    <p class="section-content" style={styles.sectionContent}>
                        In addition, tracking technologies may automatically record information such as Internet domain and host
                        names; other information about your geographic location; IP addresses; browser software and operating
                        system types; CPU type; your manner of connecting to the Internet (e.g., connection speed through
                        broadband access); click paths; and dates and times that our Websites and mobile application(s) are
                        accessed. We may use a variety of other technologies that collect similar information for security and
                        fraud detection purposes. We may also analyze information that does not include Personal Information for
                        trends and statistics.
                    </p>
                </div>

                <div class="section" style={styles.section}>
                    <h2 class="section-title" style={styles.sectionTitle}>Use of Collected Information:</h2>
                    <p class="section-content" style={styles.sectionContent}>
                        Any details collected from Prozo customers are required in order to provide you with our products and/or
                        services, and a high level of customer service. Correspondence is recorded in order to provide service
                        references, and to assist in our staff development. We use the Personal Information and other
                        information you provide for purposes including, but not limited to:
                    </p>
                    <ul class="section-content" style={styles.sectionContent}>
                        <li>Fulfilling requests for products, services or information;</li>
                        <li>Tracking and confirming orders;</li>
                        <li>Delivering products;</li>
                        <li>Providing customer services;</li>
                        <li>Administering promotions or surveys;</li>
                        <li>Offering new products and services;</li>
                        <li>Improving the effectiveness of our Websites and mobile application(s), our marketing endeavors and
                            our services and offers;</li>
                        <li>Conducting research and analysis;</li>
                        <li>Sending marketing communications; and/or</li>
                        <li>Performing other business activities as needed, or as described elsewhere within this Policy.</li>
                    </ul>
                    <p class="section-content" style={styles.sectionContent}>
                        In certain circumstances, we may share aggregated non-personal information with third parties. When we
                        use third parties to assist us in processing your Personal Information, we require that they comply with
                        our Privacy Policy and any other appropriate confidentiality and security measures. We may also share
                        such information with third parties in limited circumstances, including when complying with legal
                        process, preventing fraud or imminent harm, and ensuring the security of our network and services.
                    </p>
                    <p class="section-content" style={styles.sectionContent}>
                        Where we propose to use your Personal Information for any other uses we will ensure that we notify you
                        first. You will also be given the opportunity to withhold or withdraw your consent for your use other
                        than as listed above.
                    </p>
                </div>
                <div class="section" style={styles.section}>
                    <h2 class="section-title" style={styles.sectionTitle}>Storage and Confidentiality of Collected Information:
                    </h2>
                    <p class="section-content" style={styles.sectionContent}>
                        The security of your Personal Information is important to us. We will keep confidential and protect your
                        Personal Information except where disclosure is required or permitted by law. When you enter sensitive
                        information (such as credit card numbers) on our websites and mobile application(s), we encrypt that
                        information using secure socket layer technology (SSL). When Credit Card /Debit Card details are
                        collected, we simply pass them on in order to be processed as required. We never permanently store
                        complete Credit Card/Debit Card details.
                    </p>
                    <p class="section-content" style={styles.sectionContent}>
                        We follow generally accepted industry standards to protect the Personal Information submitted to us,
                        both during transmission and once we receive it. All information we gather is securely stored within
                        databases controlled by us. The databases are stored on servers secured behind a firewall; access to the
                        servers is password-protected and is strictly limited. No method of transmission over the Internet, or
                        method of electronic storage, is 100% secure, however. Therefore, while we strive to use commercially
                        acceptable means to protect your Personal Information, we cannot guarantee its absolute security. If you
                        have any questions about security on our Websites and mobile application(s), you can email us at
                        cs@prozo.com.
                    </p>
                    <p class="section-content" style={styles.sectionContent}>
                        We limit access to Personal Information to employees, agents, advisors and consultants who we believe
                        reasonably need to come into contact with that information to provide products or Services to you or in
                        order to do their jobs.
                    </p>
                    <p class="section-content" style={styles.sectionContent}>
                        Third parties provide certain Services available on our Websites and mobile application(s) and on our
                        behalf. We may provide information, including Personal Information, to third-party service providers to
                        help us deliver our products, information, and Services efficiently and effectively. Service providers
                        are also an important means by which we maintain our Websites and mobile application(s) and mailing
                        lists. We will take reasonable steps to ensure that these third-party service providers are obligated to
                        protect Personal Information on our behalf through confidentiality agreements and otherwise. We do not
                        intend to transfer Personal Information without your consent to third parties who are not bound to act
                        on our behalf unless such transfer is legally required. Similarly, it is against our policy to sell
                        Personal Information collected online without consent.
                    </p>
                    <p class="section-content" style={styles.sectionContent}>
                        If you choose to provide us with your Personal Information, we may transfer that Personal Information
                        from your country or jurisdiction to other countries or jurisdictions around the world. Your Personal
                        Information may be collected and stored on servers located in the territory of India and such other
                        countries as we may disclose from time to time.
                    </p>
                </div>
                <div class="section" style={styles.section}>
                    <h2 class="section-title" style={styles.sectionTitle}>RIGHTS IN RELATION TO PERSONAL DATA:
                    </h2>
                    <p class="section-content" style={styles.sectionContent}>
                        Depending on the law that applies, you may have a right to access and rectify or erase your personal
                        data or receive a copy of your personal data, restrict or object to the active processing of your data,
                        ask us to share (port) your personal information to another entity, withdraw any consent you provided to
                        us to process your data, a right to lodge a complaint with a statutory authority and such other rights
                        as may be relevant under applicable laws. To exercise these rights, you can write to us at
                        privacy@prozo.com. We will respond to your request in accordance with applicable law.

                    </p>
                    <p class="section-content" style={styles.sectionContent}>
                        You may opt-out of direct marketing communications or the profiling we carry out for marketing purposes
                        by writing to us at
                        <a href="mailto:privacy@prozo.com" style={styles.sectionContentLink}>privacy@prozo.com</a>.
                    </p>
                    <p class="section-content" style={styles.sectionContent}>
                        Do note that if you do not allow us to collect or process the required personal information or withdraw
                        the consent to process the same for the required purposes, you may not be able to access or use the
                        services for which your information was sought.
                    </p>
                </div>
                <div class="section" style={styles.section}>
                    <h2 class="section-title" style={styles.sectionTitle}>Access to Collected Information:
                    </h2>
                    <p class="section-content" style={styles.sectionContent}>
                        If your personally identifiable information changes, or if you no longer desire our service, you may
                        correct, update, delete or deactivate it by emailing us at cs@prozo.com
                    </p>
                </div>
                <div class="section" style={styles.section}>
                    <h2 class="section-title" style={styles.sectionTitle}>Children's Privacy:
                    </h2>
                    <p class="section-content" style={styles.sectionContent}>
                        Children are not eligible to use our Sites and app(s) without the consent of their parents or legal
                        guardian and we ask that minors (under the age of 18) do not submit any Personal Information to us. If
                        you are under the age of 18, you cannot sell or purchase any items on the Sites and app(s). If you wish
                        to purchase or sell an item on the Sites and app(s) such purchase or sale may be made by your legal
                        guardian or parents who have registered as users of the Sites and app(s).
                    </p>
                </div>
                <div class="section" style={styles.section}>
                    <h2 class="section-title" style={styles.sectionTitle}>Orders :

                    </h2>
                    <p class="section-content" style={styles.sectionContent}>
                        If you purchase a product/service using Prozo, we may request certain Personal Information from you. You
                        may be required to provide contact information (such as name, email, and postal address) and financial
                        information (such as credit card/debit card number, expiration date). If we face any issues in
                        processing an order for you, we may use this information to contact you.
                    </p>
                </div>
                <div class="section" style={styles.section}>
                    <h2 class="section-title" style={styles.sectionTitle}>Third Parties :
                    </h2>
                    <p class="section-content" style={styles.sectionContent}>
                        Prozo may at its discretion use other third parties to provide essential services on our sites and
                        app(s) or for our business processes. We may share your details as necessary for the third party to
                        provide that service and require such third parties to be in compliance with the terms of this Policy
                        and applicable confidentiality agreements. These third parties are prohibited from using your Personal
                        Information for any other purpose. Prozo does not share any information with third parties for any
                        unknown or unrelated uses.
                    </p>
                </div>
                <div class="section" style={styles.section}>
                    <h2 class="section-title" style={styles.sectionTitle}>Communications
                    </h2>
                    <p class="section-content" style={styles.sectionContent}>
                        Prozo uses Personal Information for essential communications, such as emails, accounts information, and
                        critical service details. We may also use this information for other purposes, including some periodic
                        newsletters and exclusive promotional emails offering special deals. If at any time a customer wishes
                        not to receive such correspondence, they can request to be removed from any mailing lists by emailing us
                        at cs@prozo.com. You will be notified when your Personal Information is collected by any third party
                        that is not our agent/service provider, so you can make an informed choice as to whether or not to share
                        your information with that party. By opening the account on our website or mobile application(s), you
                        choose to do business with us and allow us to send you all business related communication in both
                        electronic and physical forms such as email, sms, whatsapp message etc.
                    </p>
                </div>
                <div class="section" style={styles.section}>
                    <h2 class="section-title" style={styles.sectionTitle}>Accuracy
                    </h2>
                    <p class="section-content" style={styles.sectionContent}>
                        To the extent that you do provide us with Personal Information, we wish to maintain accurate and current
                        Personal Information. Where we collect Personal Information from you on our Websites and mobile
                        application(s), should you need to update or correct that Personal Information, please contact us and we
                        will make reasonable efforts to incorporate the changes in your Personal Information that we hold as
                        soon as practicable. You can obtain the information we currently hold on you by emailing us at
                        cs@prozo.com. We will use our best efforts to send you the information within 14 days of receipt of your
                        email.
                    </p>
                </div>
                <div class="section" style={styles.section}>
                    <h2 class="section-title" style={styles.sectionTitle}>Legal
                    </h2>
                    <p class="section-content" style={styles.sectionContent}>
                        On rare occasions, we may be required to disclose your Personal Information due to legal or regulatory
                        requirements. In such instances, we reserve the right to disclose your Personal Information as required
                        in order to comply with our legal obligations, including but not limited to complying with court orders,
                        warrants, subpoenas, service of process requirements, or discovery requests. We may also disclose
                        information about our users to law enforcement officers or others, in the good faith that such
                        disclosure is reasonably necessary to enforce the Terms and/or Policy; respond to claims that any User
                        Content and/or Educational Materials violates the rights of third-parties; or protect the rights,
                        property, or personal safety of Prozo, our users or the general public. You agree and acknowledge that
                        we may not inform you prior to or after disclosures made according to this section.
                    </p>
                </div>
                <div class="section" style={styles.section}>
                    <h2 class="section-title" style={styles.sectionTitle}>Change of Control
                    </h2>
                    <p class="section-content" style={styles.sectionContent}>
                        In the event that ownership or control of Prozo was to change, your Personal Information may be
                        transferred. If such a transfer results in a material change in the use of your Personal Information,
                        Prozo will provide notice about the choices you have to decline to permit such a transfer.
                    </p>
                </div>
                <div class="section" style={styles.section}>
                    <h2 class="section-title" style={styles.sectionTitle}>Third Party Websites and Links

                    </h2>
                    <p class="section-content" style={styles.sectionContent}>
                        Links on the Prozo Sites and app(s) to external entities are not covered within this Policy. If you
                        click on a link to a third party site or otherwise leave the Prozo Websites and mobile application(s),
                        you will go to a website or mobile application(s) that is outside the purview of our control. Since we
                        cannot control the activities of third parties, we cannot accept responsibility for any use of your
                        Personal Information by such third parties, and we cannot guarantee that they will adhere to the same
                        privacy and security practices as we do. The use of Personal Information by such websites shall be
                        governed by the privacy policies provided therein. Therefore, if you visit a third party website that is
                        linked to our Websites and mobile application(s), you should consult that website's privacy policy
                        before providing any Personal Information. The terms and conditions set out in this privacy statement
                        only cover our Websites and mobile application(s) listed in the "Introduction" section.
                    </p>
                </div>
                <div class="section" style={styles.section}>
                    <h2 class="section-title" style={styles.sectionTitle}>Consent and Changes to Privacy Policy
                    </h2>
                    <p class="section-content" style={styles.sectionContent}>
                        By using our Websites and mobile application(s), you consent to the terms of this Policy and to our use
                        and management of Personal Information for the purposes and in the manner herein provided. If we decide
                        to change our Policy, we will post those changes to this privacy statement, the homepage, and other
                        places we deem appropriate so that you are aware of what information we collect, how we use it, and
                        under what circumstances, if any, we disclose it. We reserve the right to modify this Policy at any
                        time, so please review it frequently. If we make material changes to this Policy, we will notify you
                        here, by email, or by means of a notice on our homepage.
                    </p>
                </div>
                <div class="section" style={styles.section}>
                    <h2 class="section-title" style={styles.sectionTitle}>Governing Law and Jurisdiction
                    </h2>
                    <p class="section-content" style={styles.sectionContent}>
                        Your visit and any dispute over privacy are subject to this Policy. The said Policy shall be governed by
                        and construed in accordance with the laws of the Republic of India. Further, it is irrevocably and
                        unconditionally agreed that the courts of Delhi, India shall have exclusive jurisdiction to entertain
                        any proceedings in relation to any disputes arising out of the same.
                    </p>
                </div>
                <div class="section" style={styles.section}>
                    <h2 class="section-title" style={styles.sectionTitle}>Return and Refund Policy
                    </h2>
                    <p class="section-content" style={styles.sectionContent}>
                        Any product bought from Prozo or it’s affiliate website is returnable as per the specific terms
                        mentioned on the invoice. If any clarification regarding any order is needed, please email your return
                        request along with Orders ID and a copy of invoice to sales@prozo.com. Once approved, you will be
                        requested to send back the products to our designated warehouse and a return ID will be allocated to
                        you. Once we receive the product into our warehouse safely, we will initiate a refund to the bank
                        account provided by you. Any escalation of refund and return requested can be made at admin@prozo.com
                    </p>
                </div>
                <p class="section-content" style={styles.sectionContent}>
                    Compliance Officer Name: Vaibhav Dhawan<br />
                    Compliance Officer Mail Id: <a href="mailto:compliance.officer@prozo.com" style={styles.sectionContentLink}>compliance.officer@prozo.com</a>
                </p>
                <p class="section-content" style={styles.sectionContent}>
                    <strong>Corporate Office:</strong> Prozo Integrated Logistics Private Limited (formerly, Prozo Distribution
                    Private
                    Limited) Enkay Tower, Plot number B&B1, A Wing,Third Floor, Vanijya Nikunj, Udyog Vihar Phase V,
                    Gurugram, Haryana – 122016<br />
                    For more information please reach out to <a href="mailto:privacy@prozo.com" style={styles.sectionContentLink}>privacy@prozo.com</a>
                </p>
            </div>
        </div>
    );
};

export default PrivacyPolicy;
